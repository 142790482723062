import React, { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';

const imageSources = [
    { url: "http://missioncontrol.observatory.csusb.edu/images/ImageLastFTP_AllSKY.jpg", title: "All Sky Camera", key: "allSky", cols: 2, rows: 2 },
    { url: "http://missioncontrol.observatory.csusb.edu/images/RH.jpg", title: "Relative Humidity", key: "humidity", cols: 1, rows: 1 },
    { url: "http://missioncontrol.observatory.csusb.edu/images/temp.jpg", title: "Temperature", key: "temperature", cols: 1, rows: 1 },
];

const AllSkyCameraModule = () => {
    const [imageTimestamps, setImageTimestamps] = useState({});
    const [loadedImages, setLoadedImages] = useState({});
    const isMobile = useMediaQuery('(max-width:600px)');
    const cols = isMobile ? 1 : 3;

    useEffect(() => {
        refreshImages();
        const interval = setInterval(refreshImages, 10000); // Refresh images every 10 seconds
        return () => clearInterval(interval);
    }, []);

    const refreshImages = () => {
        const timestamp = new Date().getTime();
        setImageTimestamps({
            allSky: timestamp,
            humidity: timestamp,
            temperature: timestamp,
        });
        preloadImages(timestamp);
    };

    const preloadImages = (timestamp) => {
        imageSources.forEach((image) => {
            const img = new Image();
            img.src = `${image.url}?t=${timestamp}`;
            img.onload = () => {
                setLoadedImages((prev) => ({ ...prev, [image.key]: true }));
            };
        });
    };

    return (
        <div className="all_sky_camera_module" style={{ padding: '5px 15px 15px' }}>  
            <h1 style={{ textAlign: 'left', margin: '5px 0 10px', paddingLeft: '10px' }}>All Sky Camera</h1>

            <ImageList variant="quilted" cols={cols} rowHeight={isMobile ? 'auto' : 200} gap={10}>
                {imageSources.map((image) => (
                    <ImageListItem key={image.key} cols={isMobile ? 1 : image.cols} rows={isMobile ? 1 : image.rows} style={{ width: '100%' }}>
                        
                        {/* Show Skeleton Loader while the new image is loading */}
                        {!loadedImages[image.key] && (
                            <Skeleton 
                                variant="rectangular" 
                                width="100%" 
                                height={isMobile ? 250 : '100%'} 
                                animation="wave" 
                                style={{ borderRadius: '10px', position: 'absolute', top: 0, left: 0 }}
                            />
                        )}

                        <img
                            src={`${image.url}?t=${imageTimestamps[image.key]}`}
                            alt={image.title}
                            loading="lazy"
                            onLoad={() => setLoadedImages((prev) => ({ ...prev, [image.key]: true }))}
                            style={{ 
                                width: '100%', 
                                height: isMobile ? 'auto' : '100%', 
                                objectFit: 'cover', 
                                borderRadius: '10px',
                                visibility: loadedImages[image.key] ? 'visible' : 'hidden'  // Keeps space but hides until loaded
                            }}
                        />

                        {/* Image Title Overlay */}
                        <div style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            color: 'white',
                            textAlign: 'center',
                            padding: '12px',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            borderBottomLeftRadius: '10px',
                            borderBottomRightRadius: '10px'
                        }}>
                            {image.title}
                        </div>

                    </ImageListItem>
                ))}
            </ImageList>
        </div>
    );
};

export default AllSkyCameraModule;
