import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function LightModule() {
  const [relayUnits, setRelayUnits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alertOpen, setAlertOpen] = useState(true);

  const fetchData = async () => {
    try {
      const response = await fetch('api/light-status');
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      const units = Object.keys(data)
        .map((key) => ({
          id: key,
          ...data[key],
        }))
        .filter((unit) => unit.Name);

      setRelayUnits(units);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <Snackbar 
        open={alertOpen} 
        autoHideDuration={6000} 
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
          The observatory light control system is currently unavailable. A maintenance request has been submitted for resolution.
        </MuiAlert>
      </Snackbar>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '20px' }}>
        <Button variant="contained" color="primary" disabled>
          All Lights On
        </Button>
        <Button variant="contained" style={{ backgroundColor: '#003366', color: 'white' }} disabled>
          All Lights Off
        </Button>
        <Button variant="outlined" disabled>
          Observing Lights
        </Button>
      </div>
      {relayUnits.map((unit) => (
        <ListItem key={unit.id}>
          <Switch checked={unit.Status === 'ON'} color="primary" inputProps={{ 'aria-label': 'status' }} disabled />
          <span>{unit.Name}</span>
        </ListItem>
      ))}
    </div>
  );
}

export default LightModule;